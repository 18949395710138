<template>
  <base-section
    id="stack"
    class="py-0"
  >
    <v-container class="fill-height px-4 py-0">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="1200"
        width="100%"
      >
        <v-container>
          <div class="d-flex justify-start">
          <base-section-heading
            :title="$t('stack')"
            :align="$vuetify.breakpoint.width >= 768 ? 'left' : 'center'"
            style="width: max-content"
          />
          </div>
        </v-container>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(card, i) in filteredStacks"
              :key="i"
              cols="12"
              sm="12"
              md="4"
            >
              <base-avatar-card
                v-bind="card"
                vertical
                class="px-9 py-3 card_slack mb-0"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
      <v-container class="fill-height px-4">
        <div
          v-if="isNeedShowMore"
          class="d-flex justify-center mx-auto"
        >
          <v-btn
            width="100%"
            min-height="60"
            class="btn_more mt-lg-12"
            @click="clickShowMore()"
          >
            {{ $t('showMore') }}
          </v-btn>
        </div>
      </v-container>
    </v-container>
  </base-section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SectionStack',

    data: () => ({
      rows: 2,
      filteredStacks: [],
    }),
    computed: {
      isNeedShowMore () {
        return (this.rows * ((this.$vuetify.breakpoint.width >= 768) ? 3 : 1)) < this.stacks.length
      },
      ...mapGetters({
        stacks: 'stack/stacks',
        serviceSelectedItems: 'services/selectedItems',
      }),
    },
    watch: {
      stacks: function () {
        this.filterStacks()
      },
      serviceSelectedItems: function () {
        this.filterStacks()
      },
    },
    mounted () {
      this.$store.dispatch('stack/fetchStacks')
    },
    methods: {
      clickShowMore () {
        this.rows++
        this.filterStacks()
      },
      filterStacks () {
        var filteredStacks = this.stacks
        if (typeof this.serviceSelectedItems !== 'undefined' && this.serviceSelectedItems.length > 0) {
          // console.log('self.serviceSelectedItems: ' + JSON.stringify(this.serviceSelectedItems))
          const self = this
          filteredStacks = filteredStacks.filter(function (fs) {
            // console.log('self.serviceSelectedItem: ' + JSON.stringify(fs))
            return self.serviceSelectedItems.some(function (service) {
              return service.stacks.some(function (stack) {
                return stack.id === fs.id
              })
            })
          })
        }

        const minIndex = Math.min(filteredStacks.length, this.rows * 3)
        console.log('minIndex: ' + minIndex + ' filteredStacks.length :' + filteredStacks.length)
        this.filteredStacks = filteredStacks.slice(0, minIndex)
      },
    },
  }
</script>
<style lang="sass">
  .card_slack
    background-color: #121212 !important
    min-height: 550px !important
  .btn_more
    color: white !important
    border: 2px solid #0A8C4C
    background: transparent
    text-align: center
</style>
